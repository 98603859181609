import React from 'react';

function CloseIcon() {
  return (
    <span>
      <svg xmlns='http://www.w3.org/2000/svg' width='366' height='365' fill='none' viewBox='0 0 366 365'>
        <path
          fill='currentColor'
          d='M13.88 352.12c8.96 8.961 20.16 12.879 31.922 12.879s22.961-4.48 31.922-12.879l105.28-105.84 105.28 105.28c8.961 8.961 20.16 12.879 31.922 12.879s22.961-4.48 31.922-12.879c17.359-17.359 17.359-45.922 0-63.281l-105.84-105.28 105.28-105.28c17.359-17.359 17.359-45.922 0-63.281s-45.922-17.359-63.281 0l-105.28 105.28-105.28-105.84c-17.359-17.359-45.922-17.359-63.281 0s-17.359 45.922 0 63.281l105.28 105.84-105.84 105.28c-17.359 17.918-17.359 45.918 0 63.84l-.006.001z'
        />
      </svg>
    </span>
  );
}

export default CloseIcon;
