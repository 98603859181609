//SIGNUP
export const SIGNUP_IDLE = 'SIGNUP_IDLE';
export const SIGNUP_REQUESTED = 'SIGNUP_REQUESTED';
export const SIGNUP_SUCCESSFUL = 'SIGNUP_SUCCESSFUL';
export const SIGNUP_FAILED = 'SIGNUP_FAILED';

//IDENTITY
export const IDENTITY_IDLE = 'IDENTITY_IDLE';
export const IDENTITY_REQUESTED = 'IDENTITY_REQUESTED';
export const IDENTITY_RECEIVED = 'IDENTITY_RECEIVED';
export const IDENTITY_FAILED = 'IDENTITY_FAILED';

//CONTENT

export const CONTENT_REQUESTED = 'CONTENT_REQUESTED';
export const CONTENT_RECEIVED = 'CONTENT_RECEIVED';
export const CONTENT_FAILED = 'CONTENT_FAILED';
export const CONTENT_IDLE = 'CONTENT_IDLE';

//FIREBASE
export const REQUEST_IDLE = 'REQUEST_IDLE';
export const REQUEST_INITIALISED = 'REQUEST_INITIALISED';
export const REQUEST_SUCCESSFUL = 'REQUEST_SUCCESSFUL';
export const REQUEST_FAILED = 'REQUEST_FAILED';

//PRACTICE

export const EMPTY_EXERCISE = 'EMPTY_EXERCISE';
export const ATTEMPT_IN_PROGRESS = 'ATTEMPT_IN_PROGRESS';
export const EXERCISE_CREATED = 'EXERCISE_CREATED';
export const EXERCISE_FAILED = 'EXERCISE_FAILED';
export const PRACTICE_SUCCESSFUL = 'PRACTICE_SUCCESSFUL';
export const PRACTICE_FAILED = 'PRACTICE_FAILED';
export const PRACTICE_IDLE = 'PRACTICE_IDLE';

export type AssembleStatusType = 'EMPTY_EXERCISE' | 'ATTEMPT_IN_PROGRESS' | 'EXERCISE_CREATED' | 'EXERCISE_FAILED';
export type PracticeResultType = 'PRACTICE_SUCCESSFUL' | 'PRACTICE_FAILED' | 'PRACTICE_IDLE';
